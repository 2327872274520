<template>
  <div>
    <div style="font-size: 0.4rem;">请选择您要反馈的问题类型</div>
    <el-check-tag class="topbtn" :checked="checked" @click="type = 1" @change="onChange(0)">产品建议</el-check-tag>
    <el-check-tag class="topbtn" :checked="checked1" @click="type = 2" @change="onChange(1)">使用问题</el-check-tag>
    <el-check-tag class="topbtn" :checked="checked2" @click="type = 3" @change="onChange(2)">我要吐槽</el-check-tag>
    <div class="h1">反馈内容</div>
    <!-- multiple="true" -->
    <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;">
    <div class="chatbox">
      <textarea v-model="description" maxlength="500" placeholder="请输入您的反馈内容~" class="text" name="" id="" cols="30"
        rows="10"></textarea>
      <div class="photoBox">
        <div class="loadphoto" v-for="(image, index) in selectedImages" :key="index">
          <img :src="image.url">
          <button class="rmbtn" @click="removeImage(index)">
            <svg t="1712585880065" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="5038" width="200" height="200">
              <path
                d="M329.142857 73.142857h365.714286v73.142857h-365.714286zM109.714286 190.171429h804.571428v73.142857h-804.571428zM804.571429 950.857143H219.428571V336.457143h585.142858V950.857143z m-512-73.142857h438.857142V409.6H292.571429V877.714286z"
                fill="#ffffff" p-id="5039"></path>
              <path
                d="M380.342857 504.685714h73.142857V731.428571h-73.142857zM570.514286 504.685714h73.142857V731.428571h-73.142857z"
                fill="#ffffff" p-id="5040"></path>
            </svg>
          </button>
        </div>
        <div v-if="selectedImages.length < 4" @click="photom" class="loadphoto">
          <div class="ph">
            <svg t="1712581583592" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="1518" width="200" height="200">
              <path
                d="M938.666667 553.92V768c0 64.8-52.533333 117.333333-117.333334 117.333333H202.666667c-64.8 0-117.333333-52.533333-117.333334-117.333333V256c0-64.8 52.533333-117.333333 117.333334-117.333333h618.666666c64.8 0 117.333333 52.533333 117.333334 117.333333v297.92z m-64-74.624V256a53.333333 53.333333 0 0 0-53.333334-53.333333H202.666667a53.333333 53.333333 0 0 0-53.333334 53.333333v344.48A290.090667 290.090667 0 0 1 192 597.333333a286.88 286.88 0 0 1 183.296 65.845334C427.029333 528.384 556.906667 437.333333 704 437.333333c65.706667 0 126.997333 16.778667 170.666667 41.962667z m0 82.24c-5.333333-8.32-21.130667-21.653333-43.648-32.917333C796.768 511.488 753.045333 501.333333 704 501.333333c-121.770667 0-229.130667 76.266667-270.432 188.693334-2.730667 7.445333-7.402667 20.32-13.994667 38.581333-7.68 21.301333-34.453333 28.106667-51.370666 13.056-16.437333-14.634667-28.554667-25.066667-36.138667-31.146667A222.890667 222.890667 0 0 0 192 661.333333c-14.464 0-28.725333 1.365333-42.666667 4.053334V768a53.333333 53.333333 0 0 0 53.333334 53.333333h618.666666a53.333333 53.333333 0 0 0 53.333334-53.333333V561.525333zM320 480a96 96 0 1 1 0-192 96 96 0 0 1 0 192z m0-64a32 32 0 1 0 0-64 32 32 0 0 0 0 64z"
                fill="#b4b4b4" p-id="1519"></path>
            </svg>
          </div>
          <div class="ph_text">
            上传图片
          </div>
        </div>
      </div>
      <div class="textnum">
        {{ description.length }}/500
      </div>
    </div>
    <div style="height: 0.4rem;"></div>
    <div style="font-size: 0.4rem;">您的联系方式</div>
    <el-input v-model="contact" style="height: 1rem;" clearable placeholder="请输入手机号或邮箱以便客服及时联系您" />
    <div style="height: 0.4rem;"></div>
    <el-button @click="submit" type="primary" style="width: 100%;height: 1rem;" round>提交</el-button>
    <el-dialog v-model="centerDialogVisible" :title="typetitle" width="8rem" style="border-radius: 8px;"
      center></el-dialog>
    <el-dialog v-model="centerDialogVisible2" :title="typetitle2" width="8rem" style="border-radius: 8px;"
      :before-close="handleClose" center></el-dialog>
  </div>
</template>

<script setup>
import { ElButton, ElInput, ElCheckTag, ElDialog, ElLoading } from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import { onMounted, ref } from 'vue'
const description = ref("")
const contact = ref("")
const fileInput = ref(null);
const selectedImages = ref([]);
const type = ref(0)
const checked = ref(false)
const checked1 = ref(false)
const checked2 = ref(false)
const centerDialogVisible = ref(false)
const centerDialogVisible2 = ref(false)
const typetitle = ref('')
const typetitle2 = ref('')
const indexphoto = ref(0)
const selectedFiles = ref([])
// const testtoken = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiJzeXNfdXNlcjoxIiwicm5TdHIiOiJzZElLaWVQQWVlczBHWVZqcWZmd1FTVU9OZkdGZ0J2aCIsImNsaWVudGlkIjoiZTVjZDdlNDg5MWJmOTVkMWQxOTIwNmNlMjRhN2IzMmUiLCJ0ZW5hbnRJZCI6IjAwMDAwMCIsInVzZXJJZCI6MSwiZGVwdElkIjoxMDN9.os3ON_XADwfT9rcSfdLrvMJECFVRlrq8OQkk9rtuDkA'
// const testclient = 'e5cd7e4891bf95d1d19206ce24a7b32e'
// const testuseId = 1
const headers = ref({
  Authorization: null,
  clientId: null,
  userId: null
})
const url = ref('https://api2.kodami.ltd');
const photom = () => {
  fileInput.value.click();
}
const onChange = (status) => {
  switch (status) {
    case 0:
      checked.value = true
      checked1.value = false
      checked2.value = false
      break;
    case 1:
      checked.value = false
      checked1.value = true
      checked2.value = false
      break;
    case 2:
      checked.value = false
      checked1.value = false
      checked2.value = true
      break;
    default:
      break;
  }
}
const handleFileChange = async () => {
  const formData = new FormData();
  selectedFiles.value.forEach(file => {
    formData.append('file', file);
  });
  const loading = ElLoading.service({
    lock: true,
    text: '图片加载中',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await axios.post(url.value + '/resource/oss/upload', formData,
    {
      headers: {
        'Authorization':'Bearer ' + headers.value.Authorization,
        'ClientId': headers.value.clientId
      }
    })
    .then(response => {
      const data = {
        fileName: response.data.data.fileName,
        url: response.data.data.url
      }
      selectedImages.value.push(data)
    }).catch((err)=> {
      typetitle.value = err.message
      centerDialogVisible.value = true;
      // loading.close()
    })
    loading.close()

}
const removeImage = (index) => {
  selectedImages.value.splice(index, 1);
  indexphoto.value -= 1
}
const handleFileUpload = () => {
  selectedFiles.value = Array.from(fileInput.value.files);
  handleFileChange()
}
const submit = async () => {
  if (type.value == 0) {
    typetitle.value = '请选择问题类型'
    centerDialogVisible.value = true;
    return
  } else if (description.value == '') {
    typetitle.value = '请填写内容反馈'
    centerDialogVisible.value = true;
    return
  } else if (contact.value == '') {
    typetitle.value = '请填写联系方式'
    centerDialogVisible.value = true;
    return
  }
  const regExpEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  const regExpPhone = /^1[3456789]\d{9}$/;
  if (!regExpEmail.test(contact.value) && !regExpPhone.test(contact.value)) {
    typetitle.value = '请输入有效的邮箱或手机号码'
    centerDialogVisible.value = true;
    return
  }
  const formData = new FormData();
  for (let i = 0; i < selectedImages.value.length; i++) {
    const file = selectedImages.value[i];
    formData.append('images[]', file);
  }
  formData.append('description', description.value);
  formData.append('contact', contact.value);
  formData.append('type', type.value);
  const selectedImagesString = concatenateFileNames(selectedImages.value)
  const loading = ElLoading.service({
    lock: true,
    text: '提交中',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await axios.post(url.value + '/cyxl/userSuggestion',
    {
      content: description.value,
      imageUrl: selectedImagesString,
      contact: contact.value,
      suggestionType: type.value,
      userId: headers.value.userId
    },
    {
      headers: {
        'Authorization':'Bearer ' +  headers.value.Authorization,
        'ClientId': headers.value.clientId,
      }
    })
    .then((res) => {
      if (res.data.code == 200) {
        loading.close()
        typetitle2.value = '感谢您的反馈'
        centerDialogVisible2.value = true;
      } else {
        loading.close()
        typetitle2.value = res.data.msg
        centerDialogVisible2.value = true;
      }

    })

}
const concatenateFileNames = (selectedImages) => {
  const fileNames = selectedImages.map(image => image.fileName);
  const concatenatedFileNames = fileNames.join(', ');
  return concatenatedFileNames;
}
const handleClose = () => {
  centerDialogVisible2.value = false
  intidata();
}
const intidata = () => {
  description.value = ""
  contact.value = ""
  selectedImages.value = [];
  type.value = 0
  checked.value = false
  checked1.value = false
  checked2.value = false
  typetitle.value = ''
}
const getParameterByName = (name, url = null) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
onMounted(() => {
  const token = getParameterByName('token'); // 返回值为 "123"
  const clientId = getParameterByName('clientId'); // 返回值为 "John"
  const userId = getParameterByName('userId'); // 返回值为 "John"
  url.value = getParameterByName('url')
  // let test = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiJzeXNfdXNlcjoxIiwicm5TdHIiOiJGZXVoNTFadURJbzZ4eThtOUJ1NjJjZUt5MkxYaGlHRCIsImNsaWVudGlkIjoiZTVjZDdlNDg5MWJmOTVkMWQxOTIwNmNlMjRhN2IzMmUiLCJ0ZW5hbnRJZCI6IjAwMDAwMCIsInVzZXJJZCI6MSwiZGVwdElkIjoxMDN9.69Km-2uNIkJxQwW6Kg-Gzf6fY0GadTTM1OJPHe-vL_I'
  // let clientIds = 'e5cd7e4891bf95d1d19206ce24a7b32e'
  headers.value = {
    Authorization: `${token}`,
    clientId: clientId,
    userId: userId
  };
  // console.log('token:'+token);
  // console.log('clientId:'+clientId);
  // console.log('userId:'+userId);
  // console.log('url:'+url.value);
})
</script>

<style>
.el-check-tag {
  margin-right: .2rem;
  height: 20px;
  line-height: 20px;
}

.chatbox {
  background: rgba(245, 246, 249, 1);
  width: 100%;
  border-radius: 15px;
  padding-left: 0.3rem;
  padding-right: 0.55rem;
  padding-top: 0.4rem;
  padding-bottom: .4rem;

  box-sizing: border-box;
}

.loadphoto {
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #d3d3d3;
  position: relative;
  overflow: hidden;
}

.loadphoto img {
  width: 100%;
  object-fit: cover;
}

.topbtn {
  width: 1.4rem;
  text-align: center;
  height: .5rem;
  line-height: .5rem;
  font-size: .3rem;
}

.rmbtn {
  position: absolute;
  width: 0.6rem;
  height: 0.6rem;
  background: #000;
  top: -3px;
  right: -3px;
  border: none;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rmbtn svg {
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  left: 2px;
  bottom: 4px;

}

.h1 {
  font-size: 0.47rem;
  margin: .3rem 0;
}

.text {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  font-size: .35rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.ph {
  width: .8rem;
  height: .8rem;
  margin-bottom: .1rem;
}

.ph svg {
  width: 100%;
  height: 100%;
}

.ph_text {
  font-size: .3rem;
  color: #b4b4b4;
  font-weight: 600;
}

.photoBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 10px;
}

.textnum {
  font-size: .35rem;
  display: flex;
  flex-direction: row-reverse;
  color: #6f6f6f;
}
</style>
